import React from "react"

import Layout from "../components/layout"
import ContactForm from "../components/contactForm"
import SEO from "../components/seo"

/** @jsx jsx */
import { jsx, Card, Grid, Heading, Image, Text, Styled } from "theme-ui"

const Page = ({ location }) => (
  <Layout>
    <SEO
      location={location}
      title="Kontakt"
      descrption="Schreiben Sie uns eine Nachricht über dieses Kontaktformular."
    />
    <Heading as="h1" variant="siteTitle">
      Kontakt
    </Heading>
    <ContactForm />
  </Layout>
)

export default Page
