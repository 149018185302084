import { useState } from "react"
import { useForm } from "react-hook-form"

/** @jsx jsx */
import {
  jsx,
  Alert,
  Box,
  Heading,
  Text,
  Styled,
  Button,
  Label,
  Input,
  Select,
  Textarea,
  Checkbox,
} from "theme-ui"

import { formsBackendEndpoint } from "../config"

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

const ContactForm = () => {
  const { register, handleSubmit, errors } = useForm()
  const [success, setSuccess] = useState(false)

  const onSubmit = async (data, e) => {
    e.preventDefault()
    await fetch(`https://${formsBackendEndpoint}/contactforms`, {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ ...data }),
    })
      .then(response => {
        console.log(response)
        if (response.status != 200) {
          throw "Da ist leider etwas schief gelaufen. Schreiben Sie uns bitte eine E-Mail..."
        } else {
          setSuccess(true)
        }
      })
      .catch(error => alert(error))
  }

  const warning = "⚠ Eingabe erforderlich."

  if (!success) {
    return (
      <Box as="form" name="contact" onSubmit={handleSubmit(onSubmit)}>
        <Box sx={{ display: ["block", "flex"] }}>
          <Box sx={{ flex: ["30%", "50%", "30%"], px: 3 }}>
            <Label htmlFor="anrede">Anrede</Label>
            <Select
              name="anrede"
              id="anrede"
              mb={3}
              ref={register}
              defaultValue="default"
            >
              <option value="default" disabled>
                Bitte wählen...
              </option>
              <option>Frau</option>
              <option>Herr</option>
            </Select>
          </Box>
          <Box sx={{ flex: ["100%", "50%", "30%"], px: 3 }}>
            <Label htmlFor="organisation">Firma/Organisation</Label>
            <Input
              name="organisation"
              id="organisation"
              mb={3}
              ref={register}
            />
          </Box>
        </Box>
        <Box sx={{ display: ["block", "flex"] }}>
          <Box sx={{ flex: "50%", px: 3 }}>
            <Label htmlFor="vorname">Vorname *</Label>
            <Input
              name="vorname"
              id="vorname"
              mb={3}
              ref={register({ required: true })}
              sx={{ mb: errors.vorname && 0 }}
            />
            {errors.vorname && <Alert variant="form">{warning}</Alert>}
          </Box>
          <Box sx={{ flex: "50%", px: 3 }}>
            <Label htmlFor="nachname">Nachname *</Label>
            <Input
              name="nachname"
              id="nachname"
              mb={3}
              ref={register({ required: true })}
              sx={{ mb: errors.nachname && 0 }}
            />
            {errors.nachname && <Alert variant="form">{warning}</Alert>}
          </Box>
        </Box>
        <Box sx={{ display: ["block", "flex"] }}>
          <Box sx={{ flex: "50%", px: 3 }}>
            <Label htmlFor="street">Straße/Hausnummer</Label>
            <Input name="street" id="street" mb={3} ref={register} />
          </Box>
          <Box sx={{ flex: "15%", px: 3 }}>
            <Label htmlFor="postalCode">PLZ</Label>
            <Input name="postalCode" id="postalCode" mb={3} ref={register} />
          </Box>
          <Box sx={{ flex: "35%", px: 3 }}>
            <Label htmlFor="city">Ort</Label>
            <Input name="city" id="city" mb={3} ref={register} />
          </Box>
        </Box>
        <Box sx={{ display: ["block", "flex"] }}>
          <Box sx={{ flex: "50%", px: 3 }}>
            <Label htmlFor="country">Land</Label>
            <Input name="country" id="country" mb={3} ref={register} />
          </Box>
          <Box sx={{ flex: "50%", px: 3 }}>
            <Label htmlFor="phone">Telefon</Label>
            <Input name="phone" id="phone" mb={3} ref={register} />
          </Box>
        </Box>
        <Box sx={{ display: ["block", "flex"] }}>
          <Box sx={{ flex: "50%", px: 3 }}>
            <Label htmlFor="fax">Fax</Label>
            <Input name="fax" id="fax" mb={3} ref={register} />
          </Box>
          <Box sx={{ flex: "50%", px: 3 }}>
            <Label htmlFor="eMail">E-Mail *</Label>
            <Input
              name="eMail"
              id="eMail"
              mb={3}
              ref={register({ required: true })}
              sx={{ mb: errors.eMail && 0 }}
              type="email"
            />
            {errors.eMail && <Alert variant="form">{warning}</Alert>}
          </Box>
        </Box>
        <Box sx={{ px: 3 }}>
          <Label htmlFor="message">
            Ihre Nachricht * (Anregungen, Fragen, Kritik, etc.)
          </Label>
          <Textarea
            name="message"
            id="message"
            rows="6"
            mb={3}
            ref={register({ required: true })}
            sx={{ mb: errors.message && 0 }}
          />
          {errors.message && <Alert variant="form">{warning}</Alert>}
        </Box>
        <Box>
          <Box sx={{ px: 3 }}>
            <Label htmlFor="privacy" mb={3} sx={{ mb: errors.privacy && 0 }}>
              <Checkbox
                name="privacy"
                id="privacy"
                ref={register({ required: true })}
                sx={{ mt: 1, color: "text" }}
              />
              <Text sx={{ pl: 4 }}>
                Hiermit willige ich ein, dass meine in diesem Kontaktformular
                angegeben Daten gemäß der Datenschutzerklärung erfasst,
                übermittelt und gespeichert werden. *
              </Text>
            </Label>
            {errors.privacy && (
              <Alert variant="form">⚠ Einwilligung erforderlich.</Alert>
            )}
            <Button>Absenden</Button>
          </Box>
        </Box>
      </Box>
    )
  } else {
    return (
      <Box>
        <Heading variant="subheading" sx={{ fontStyle: "unset" }}>
          Vielen Dank für Ihre Nachricht,
          <br />
          <br />
          wir werden uns umgehend bei Ihnen melden. Nun wünschen wir Ihnen erst
          mal einen schönen Tag.
          <br />
          <br />
          Herzliche Grüße
        </Heading>
        <Text variant="default" sx={{ fontStyle: "italic", fontWeight: 700 }}>
          Uschtrin Verlag (
          <Styled.a href="mailto:service@uschtrin.de">
            service@uschtrin.de
          </Styled.a>
          )
        </Text>
      </Box>
    )
  }
}

export default ContactForm
